/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { ConsoleDemo } from 'src/components/ConsoleDemo/ConsoleDemo'
import { breakpoints } from 'src/Media'
import { useWindowSize } from 'src/utils/useWindowSize'

const wrapperCss = css`
  padding: 0 16px;
  margin: 70px 0 -50px;
`

export const ConsoleDemoMobile = () => {
  const [windowWidth] = useWindowSize()
  const isVisible = windowWidth < breakpoints.md

  return (
    <div css={wrapperCss}>
      {isVisible && <ConsoleDemo />}
    </div>
  )
}
