/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import LogoSquareSVG from 'src/assets/logo-square.svg'
import { theme } from 'src/utils/theme'

type Props = {
  text: string;
}

const quote = css`
  margin: 16px 0;
  padding: 0;
`

const textBlock = css`
  line-height: 187%;
  color: ${theme.colors.black};
  margin: 0;
`

const author = css`
  display: flex;
  margin: 16px 0;
  align-items: center;
`

const authorText = css`
  flex-grow: 1;
  margin-left: 16px;
  display: block;
  font-size: ${18 / 16}em;
  color: ${theme.colors.primaryText};
  line-height: 167%;
  font-style: normal;
`

const logo = css`
  width: 40px;
  height: 40px;
`

export const WhatDoWeDoQuote: React.FC<Props> = ({ text }) => {
  return (
    <blockquote css={quote}>
      <p css={textBlock}>
        {text}
      </p>
      <footer css={author}>
        <LogoSquareSVG css={logo} />
        <cite css={authorText}>
          RabbitPeepers Team
        </cite>
      </footer>
    </blockquote>
  )
}
