/** @jsx jsx */

import { contentText, contentTitle } from '../common/Typography'

import AdvantagesCustomerFirst from 'src/assets/handshake-deal-svgrepo-com.svg'
import AdvantagesDeliverAwesome from 'src/assets/trophy-award-svgrepo-com.svg'
import AdvantagesOneTeam from 'src/assets/brainstorm-idea-svgrepo-com.svg'
import { Container } from '../common/Container'
import React from 'react'
import { TripleItemsItem } from './TripleItemsItem'
import { jsx } from '@emotion/react'
import { mq } from 'src/utils/mq'
import styled from '@emotion/styled'

const $TripleItemsWrapper = styled.section`
  padding: 32px 16px;
  margin-top: 48px;
`

const $TripleItemsGrid = styled.div`
  margin-top: 32px !important;

  ${mq.md} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    margin-top: 48px !important;
  }
`

export const TripleItems: React.FC = () => {
  return (
    <Container>
      <$TripleItemsWrapper>
        <h1 css={[contentTitle, { textAlign: 'center' }]}>
          With RabbitPeepers, you can always expect the highest standards
        </h1>
        <$TripleItemsGrid css={[contentText]}>
          <TripleItemsItem
            icon={<AdvantagesCustomerFirst />}
            title="We are customer first"
          >
            <ul>
              <li>Commitment to quality</li>
              <li>Embrace change and innovate</li>
              <li>Exceptional standards</li>
            </ul>
          </TripleItemsItem>
          <TripleItemsItem icon={<AdvantagesOneTeam />} title="We are one team">
            <ul>
              <li>Strong technical capability</li>
              <li>Experienced team</li>
              <li>Hire and develop outstanding people</li>
            </ul>
          </TripleItemsItem>
          <TripleItemsItem
            icon={<AdvantagesDeliverAwesome />}
            title="We deliver awesome"
          >
            <ul>
              <li>Bring passion and energy</li>
              <li>Work with agility and a sense of urgency</li>
              <li>Application of new-generation technologies</li>
            </ul>
          </TripleItemsItem>
        </$TripleItemsGrid>
      </$TripleItemsWrapper>
    </Container>
  )
}
