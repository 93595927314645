/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import GridSVG from 'src/assets/grid.svg'
import { Media } from 'src/Media'
import { mq } from 'src/utils/mq'

const wrapper = css`
  position: absolute;
  width: 170px;
  top: 0;
  left: 0;
  overflow: hidden;
  height: auto;

  ${mq.lg} {
    width: 200px;
    left: -50px;
  }
`

const grid = css`
  width: 100%;
  margin-left: -30px;
  height: auto;

  ${mq.lg} {
    margin-left: 0px;
  }
`

export const WhatDoWeDoTopDecoration = () => {
  return (
    <Media greaterThanOrEqual="md">
      <div css={wrapper} role="presentation">
        <GridSVG css={grid} />
      </div>
    </Media>
  )
}
