/** @jsx jsx */

import { graphql, useStaticQuery } from 'gatsby'

import { ButtonLink } from 'src/components/Button/Button'
import { Splash } from 'src/components/Splash/Splash'
import SplashImageSVG from 'src/assets/splash.svg'
import { Squidex_Landing } from 'src/types/gatsby-types'
import { jsx } from '@emotion/react'
import { path } from 'src/utils/path'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  overflow: hidden;
`

type Data = {
  squidex: {
    queryLandingContents: Squidex_Landing[]
  }
}

export const LandingSplash: React.FC = () => {
  const response: Data = useStaticQuery(
    graphql`
      query {
        squidex {
          queryLandingContents {
            flatData {
              subtitle
              title
            }
          }
        }
      }
    `,
  )

  const data = response?.squidex?.queryLandingContents?.[0]

  if (!data) {
    throw new Error('No data for splash screen')
  }

  return (
    <Wrapper>
      <Splash
        title={data.flatData.title || ''}
        subtitle={data.flatData.subtitle || ''}
        image={SplashImageSVG}
        button={
          <ButtonLink
            to={path.estimateProject}
            variant="contained"
            size="large"
          >
            Calculate project
          </ButtonLink>
        }
      />
    </Wrapper>
  )
}
