import { Layout, MetaDataProps } from 'src/components/Layout/Layout'
import { PageProps, graphql } from 'gatsby'

import { ConsoleDemoMobile } from 'src/components/ConsoleDemo/ConsoleDemoMobile'
import { InterestedInServicesForm } from 'src/components/InterestedInServicesForm/InterestedInServicesForm'
import { LandingCases } from 'src/containers/LandingCases'
import { LandingSplash } from 'src/components/LandingSplash/LandingSplash'
import { LandingTeam } from 'src/containers/LandingTeam'
import React from 'react'
import { TechnologiesList } from 'src/components/TechnologiesList/TechnologiesList'
import { Testimonials } from 'src/components/Testimonials/Testimonials'
import { TripleItems } from 'src/components/TripleItems/TripleItems'
import { WhatDoWeDo } from 'src/components/WhatDoWeDo/WhatDoWeDo'
import { motion } from 'framer-motion'

type DataProps = {
  squidex: {
    queryLandingContents: Array<{
      flatData: MetaDataProps
    }>
  }
}

const Index: React.FC<PageProps<DataProps>> = ({ data }) => {
  const flatData = data.squidex?.queryLandingContents?.[0]?.flatData || {}

  return (
    <Layout title={flatData.metaTitle || 'NO TITLE'} meta={flatData}>
      <LandingSplash />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0 }}
      >
        <WhatDoWeDo />
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <TechnologiesList />
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <TripleItems />
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <LandingTeam />
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <ConsoleDemoMobile />
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <LandingCases />
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <Testimonials />
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
        viewport={{ once: true }}
      >
        <InterestedInServicesForm />
      </motion.div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query {
    squidex {
      queryLandingContents {
        flatData {
          metaTitle
          metaDescription
          metaImage {
            ...SiteMetaImage
          }
        }
      }
    }
  }
`
