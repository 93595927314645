/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import GridSVG from 'src/assets/grid.svg'
import { Media } from 'src/Media'

const wrapper = css`
  position: absolute;
  width: 200px;
  bottom: 280px;
  right: -120px;
  overflow: hidden;
  height: auto;
`

const grid = css`
  width: 100%;
  margin-right: -40px;
  height: auto;
`

export const WhatDoWeDoBottomDecoration = () => {
  return (
    <Media greaterThanOrEqual="lg">
      <div css={wrapper} role="presentation">
        <GridSVG css={grid} />
      </div>
    </Media>
  )
}
