/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/react'
import { mq } from 'src/utils/mq'
import styled from '@emotion/styled'

const $TripleItemsItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  h3 {
    margin: 8px 0 0;
  }

  ul {
    margin: 8px 0 0;
  }
`

const $TripleItemsIconWrapper = styled.div`
  text-align: center;

  svg {
    height: 100px;
  }

  ${mq.md} {
    max-width: 250px;
  }
`

const $TripleItemsTitle = styled.h3`
  text-align: center;

  ${mq.md} {
    text-align: left;
  }
`

type TripleItemsItemProps = {
  title: string
  icon: React.ReactElement
}

export const TripleItemsItem: React.FC<TripleItemsItemProps> = ({
  title,
  icon,
  children,
}) => {
  return (
    <$TripleItemsItem>
      <$TripleItemsIconWrapper>{icon}</$TripleItemsIconWrapper>
      <$TripleItemsTitle>{title}</$TripleItemsTitle>
      {children}
    </$TripleItemsItem>
  )
}
