/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'

import { Container } from 'src/components/common/Container'
import { Squidex_Landing } from 'src/types/gatsby-types'
import { WhatDoWeDoBottomDecoration } from 'src/components/WhatDoWeDo/WhatDoWeDoBottomDecoration'
import { WhatDoWeDoItem } from 'src/components/WhatDoWeDo/WhatDoWeDoItem'
import { WhatDoWeDoSuffix } from 'src/components/WhatDoWeDo/WhatDoWeDoSuffix'
import { WhatDoWeDoTopDecoration } from 'src/components/WhatDoWeDo/WhatDoWeDoTopDecoration'
import { headingSecondary } from 'src/components/common/Typography'
import { motion } from 'framer-motion'
import { mq } from 'src/utils/mq'

const wrapper = css`
  ${mq.md} {
    position: relative;
  }
  ${mq.lg} {
    padding: 0 45px;
  }
`

const heading = css`
  ${headingSecondary}
  text-align: center;
  padding: 110px 0 88px;
  margin: 0;

  ${mq.md} {
    padding: 75px 0 64px;
  }
`

type Data = {
  squidex: {
    queryLandingContents: Squidex_Landing[]
  }
}

export const WhatDoWeDo: React.FC = () => {
  const response: Data = useStaticQuery(
    graphql`
      query {
        squidex {
          queryLandingContents {
            flatData {
              whatDoWeDoSuffix
              whatDoWeDoSuffixLink
              whatDoWeDoBlocks {
                id
                flatData {
                  buttonLink
                  buttonText
                  description
                  illustration {
                    id
                    ...IllustrationImage
                  }
                  isButtonPrimary
                  linkHref
                  linkText
                  motto
                  subtitle
                  title
                  contentCssClass
                }
              }
            }
          }
        }
      }
    `,
  )

  const data = response?.squidex?.queryLandingContents?.[0]

  if (!data) {
    throw new Error('No data for What Do We Do blocks')
  }

  return (
    <div css={{ overflow: 'hidden' }} id="what-we-do">
      <Container css={wrapper}>
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            y: {
              duration: 0,
            },
            opacity: {
              duration: 0.4,
            },
          }}
          viewport={{ once: true }}
        >
          <WhatDoWeDoTopDecoration />
        </motion.div>

        <motion.div
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          viewport={{ once: true }}
        >
          <h2 css={heading}>What we do</h2>

          <div>
            {data.flatData.whatDoWeDoBlocks?.map((item, index) => (
              <WhatDoWeDoItem index={index} key={item.id} data={item} />
            ))}
          </div>
        </motion.div>
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            y: {
              duration: 0,
            },
            opacity: {
              duration: 0.4,
            },
          }}
          viewport={{ once: true }}
        >
          <WhatDoWeDoBottomDecoration />
        </motion.div>
        <WhatDoWeDoSuffix
          whatDoWeDoSuffix={data.flatData.whatDoWeDoSuffix}
          whatDoWeDoSuffixLink={data.flatData.whatDoWeDoSuffixLink}
        />
      </Container>
    </div>
  )
}
