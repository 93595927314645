/** @jsx jsx */
import { graphql, useStaticQuery } from 'gatsby'

import { Cases } from 'src/components/Cases/Cases'
import { Squidex_Landing } from 'src/types/gatsby-types'
import { jsx } from '@emotion/react'

type Data = {
  squidex: {
    queryLandingContents: Squidex_Landing[]
  }
}

export const LandingCases: React.FC<{ title?: string }> = ({ title }) => {
  const response: Data = useStaticQuery(graphql`
    {
      squidex {
        queryLandingContents {
          flatData {
            cases {
              id
              created
              flatData {
                typeOfContent
                description
                title
                isNew
                author {
                  ...Author
                }
                preview {
                  ...PreviewImage
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = response?.squidex?.queryLandingContents?.[0]

  if (!data) {
    throw new Error('No data for LandingCases block')
  }

  if (!data.flatData.cases?.length) {
    return null
  }

  return <Cases cases={data.flatData.cases} title={title} />
}
