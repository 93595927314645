/** @jsx jsx */

import { graphql, useStaticQuery } from 'gatsby'

import { ConsoleDemo } from 'src/components/ConsoleDemo/ConsoleDemo'
import { Squidex_Landing } from 'src/types/gatsby-types'
import { Team } from 'src/components/Team/Team'
import { breakpoints } from 'src/Media'
import { jsx } from '@emotion/react'
import { useWindowSize } from 'src/utils/useWindowSize'

type Data = {
  squidex: {
    queryLandingContents: Squidex_Landing[]
  }
}

export const LandingTeam: React.FC = () => {
  const [windowWidth] = useWindowSize()

  const response: Data = useStaticQuery(graphql`
    {
      squidex {
        queryLandingContents {
          flatData {
            team {
              flatData {
                description
                name
                position
                photo {
                  ...TeamMemberImage
                }
              }
              id
            }
          }
        }
      }
    }
  `)

  const data = response?.squidex?.queryLandingContents?.[0]

  if (!data) {
    throw new Error('No data for LandingTeam block')
  }

  if (!data.flatData.team?.length) {
    return null
  }

  const displayConsole = windowWidth > breakpoints.md

  return (
    <Team team={data.flatData.team} hasDecoration={true}>
      {displayConsole && <ConsoleDemo />}
    </Team>
  )
}
