/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import React from 'react'
import Typewriter from 'typewriter-effect/dist/core'

const consoleWrapper = css`
  background: #0c1429;
  border: 1px solid #252c40;
  color: white;
  font-family: monospace;
  line-height: 1.4em;
  padding: 16px;
  flex-grow: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 200px;
`

const consoleWindow = css`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgb(60 45 111 / 10%), 0px 1px 3px rgb(60 45 111 / 15%);
  border-radius: 8px;
  background: #F7FAFC;
  max-width: 380px;
  min-width: 290px;
  margin: 16px auto;
  width: 100%;
`

const windowButton = css`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #CBD5E0;
  margin-right: 6px;
  display: block;
`

const consoleHeader = css`
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 16px;
`

export const ConsoleDemo = () => {
  const $div = React.useRef(null)

  React.useEffect(() => {
    if (!$div.current) {
      return
    }

    // eslint-disable-next-line no-new
    const typewriter = new Typewriter($div.current, {
      loop: true,
      delay: 'natural',
      deleteSpeed: 50,
    })

    typewriter.typeString('$ create awesome-startup<br>')
      .pauseFor(300)
      .typeString('$ touch rabbitpeepers')
      .changeDelay(10)
      .pauseFor(700)
      .typeString('<br>Estimation...')
      .pauseFor(500)
      .typeString(' 3 SPRINTS / 12 Weeks')
      .pauseFor(2500)
      .typeString('<br>Working on SPRINT 1...')
      .pauseFor(500)
      .deleteChars(4)
      .typeString('2...')
      .pauseFor(300)
      .deleteChars(4)
      .typeString('3...<br>')
      .pauseFor(600)
      .typeString('READY TO GO!')
      .pauseFor(500)
      .typeString('<br> Launching to production...')
      .pauseFor(2500)
      .changeDelay(1)
      .typeString('<br>PROJECT IS COMPLETED <img src="/rocket.png" width="18" height="18" />')
      .pauseFor(100)
      .typeString('<br> $')
      .pauseFor(2500)
      .changeDeleteSpeed(5)
      .deleteAll()
      .start()
  }, [])

  return (
    <div css={consoleWindow}>
      <div css={consoleHeader}>
        <i css={windowButton} />
        <i css={windowButton} />
        <i css={windowButton} />
      </div>
      <div css={consoleWrapper} ref={$div}>
        $ create awesome-startup
        <br />
        $ run rabbitpeepers
        <br />
        &gt; SUCCESS!
        <br />
      </div>
    </div>
  )
}
